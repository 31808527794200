<template>
	<div>
		<el-button type="primary" @click="$refs.dialogRef.handleOpen()">添加</el-button>
		<e-table ref="tables" :tableCols="contractTepTableConf" :dataOrigin="dataOrigin">
			<template #contract_url="scope">
				<el-link class="lh_12" type="primary" :href="scope.rowData.contract_url" target="_blank">{{ scope.rowData.contract_url }}</el-link>
			</template>
			<template #operation="scope">
				<el-button type="danger" @click="del(scope.rowData.id)">删除</el-button>
			</template>
		</e-table>
		<e-dialog title="新增合同" width="500px" ref="dialogRef" v-on:confirm="confirm">
			<el-form label-width="auto">
				<el-form-item label="合同类型">
					<el-select v-model="type" placeholder="商品分类">
						<el-option label="单品合同" value="1"></el-option>
						<el-option label="年度合同" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="合同名称">
					<el-input v-model="contract_name"></el-input>
				</el-form-item>
				<el-form-item label="上传合同">
					<el-upload
						class="upload-demo"
						:action="uploadUrl"
						multiple
						:limit="3"
						:data="data"
						:on-success="handleAvatarSuccess"
						:file-list="fileList">
						<el-button size="small" type="primary">上传合同</el-button>
						<div slot="tip" class="el-upload__tip">不超过500kb</div>
						</el-upload>
				</el-form-item>
			</el-form>
		</e-dialog>
	</div>
</template>

<script>
import {contractTepTableConf} from '@data/system/contractTep'
export default {
	name: 'ContractTep',
	data() {
		return {
			contractTepTableConf,
			dataOrigin: {
				data: [
				]
			},
			form: {},
			activeName: "first",
			currentPage: 1,
			pageSize: 10,
			total: 0,
			type: '',
			contract_name: '',
			contract_url: '',
			data: {},
			uploadUrl: ''
		}
	},
	methods: {
		handleClick() {
		},
		del(id){
			let that = this
			this.$confirm('是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.contract.del',{
					id:id}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message.success(response.data.msg);
					that.$refs.tables.loading = true
					that.GetLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
		handleAvatarSuccess(response){
			if (response.data.code === 4003) {
				this.$message.error('您没有权限操作');

				return false;
			}
			if(response.data.code == 0){
				this.$message.error(response.data.msg);
				return
			}
			this.$message.success(response.data.msg);
			this.contract_url = response.data.url
		},
		confirm(){
			let that = this
			this.post('?server=live.contract.handle.post',{
				title:this.contract_name,
				type: this.type,
				curl:this.contract_url}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.tables.loading = true
				that.GetLists()
				that.$refs.dialogRef.handleClose()
				that.contract_url = ''
				that.contract_name = ''
				that.type = ''
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		fileList(){},
		GetLists(){
			let that = this
			this.dataOrigin.data = []
			this.get('?server=live.contract.lists.get',{types:this.activeName,
				page:this.currentPage,
				query:this.form.name,
				cat:this.form.cat,
				baotime:this.form.baotime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let len = response.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data[i])
				}
				that.$refs.tables.loading = false
				// that.dataOrigin.data = response.data.data
				that.total = response.data.total
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			let token = localStorage.getItem('token')
			this.data = {"auth_token": token};
			this.$refs.tables.loading = true
			this.uploadUrl = this.appConfig.api_request_url+'?server=upload.file'

		});
		this.GetLists()
	}
}
</script>