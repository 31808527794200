
export const contractTepTableConf = [
	{
		label: '合同名称',
		prop: 'contract_name'
	},
	{
		label: '合同文件',
		prop: 'contract_url'
	},
	{
		label: '合同类型',
		prop: 'typeInfo'
	},
	{
		label: '操作',
		prop: 'operation'
	}
]